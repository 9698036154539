import {useState} from 'react';
import {usePageContext} from "vike-react/usePageContext";
import Cookies from "js-cookie";
import AppConstants from "#/src/utils/app-constants";
import {AppPageContext} from "#/src/definitions/AppDefinitions";

const useCookieCheck = () => {
  const {user} = usePageContext() as AppPageContext;
  const [isBannerDisplayed, setIsBannerDisplayed] = useState(!user?.cookiesAccepted);

  const dismissBanner = () => {
    setIsBannerDisplayed(false);
    if (user.cookiesAccepted) {
      Cookies.remove(AppConstants.COOKIES_ACCEPTED_COOKIE);
    } else {
      Cookies.set(AppConstants.COOKIES_ACCEPTED_COOKIE, "true", {expires: 365});
    }
  };

  return {isBannerDisplayed, dismissBanner};
};

export default useCookieCheck;
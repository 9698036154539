import '#/src/assets/scss/main.scss'
import '#/src/assets/scss/nprogress.scss'
import useTheme from "#/src/hooks/use-theme";
import {AccountDeletionWarning} from "#/src/components/AccountDeletionWarning";
import DOMPurify from "isomorphic-dompurify";
import {CookieBanner} from "#/src/components/CookieBanner";
import {ToastDisplay} from "#/src/components/ToastDisplay";
import * as Sentry from "@sentry/react";

export {Layout}

Sentry.init({
  dsn: "https://656e4ff1f7f10dfd69b3419d144b5bcc@o4506875646115840.ingest.us.sentry.io/4507707109801984",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.25,
  replaysOnErrorSampleRate: 1.0
});

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noreferrer noopener');
  }
});

//global layout for scss, theme detector and other utilities
function Layout({children}) {
  useTheme();
  return <>
    <ToastDisplay/>
    <AccountDeletionWarning/>
    {children}
    <CookieBanner/>
  </>
}
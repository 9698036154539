import {AppPageContext} from "#/src/definitions/AppDefinitions";
import {usePageContext} from "vike-react/usePageContext";

export function AccountDeletionWarning() {
  const {user} = usePageContext() as AppPageContext;
  if(!user || !user.loggedIn || !user.data?.deletionScheduled) {
    return <></>
  }
  return <div className={"delete-container"}>
    Twoje konto jest w kolejce do usunięcia, możesz to anulować <a className={"undo"} href={"/my-profile/settings?delete-cancel=true"}>klikając tutaj</a>.
  </div>
}
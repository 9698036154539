import { useEffect } from 'react';
import { usePageContext } from 'vike-react/usePageContext';
import {AppPageContext} from "#/src/definitions/AppDefinitions";

const useTheme = () => {
  const { user } = usePageContext() as AppPageContext;

  useEffect(() => {
    const theme = user?.lightMode ? 'light' : 'dark';
    document.documentElement.setAttribute('data-bs-theme', theme);
  }, [user?.lightMode]);
};

export default useTheme;
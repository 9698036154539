import React from 'react';
import useCookieCheck from "#/src/hooks/use-cookie-check";
import styled from "@emotion/styled";

const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  background: #272733;
  color: #eef5f8;
  text-align: center;
  border-top: 1px solid #1c1c24;
  font-size: .85em;
  z-index: 100;

  & p {
    padding: .5em;
    margin-bottom: 0;
  }

  & a {
    color: #ef1828;
    text-decoration: none;
  }

  & button {
    background: red;
    border: 0;
    color: white;
    margin-left: .5em;
    padding: 0.25em 1em;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: bold
  }
`;

export function CookieBanner() {
  const {isBannerDisplayed, dismissBanner} = useCookieCheck();

  if (!isBannerDisplayed) {
    return <></>;
  }

  return <Container style={{display: 'block'}}>
    <p>
      Korzystając ze strony zgadzasz się na użycie plików cookie. <a href={"/polityka-prywatnosci"}>Więcej informacji.</a>
      <button onClick={dismissBanner}>
        OK
      </button>
    </p>
  </Container>
}
